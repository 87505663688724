import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontSizes, TextColors } from '../Types';
import { Icon, IconName } from '../Icon';
import Text from '../text';
import { MouseEvent, useState } from 'react';
import styles from './sidebar.module.scss';

interface SidebarProps {
    logo: IconName;
    items: Array<ParentMenuItemProps>;
}

interface ParentMenuItemProps {
    title: string;
    icon: IconName;
    href: string;
    active?: boolean;
    subitems?: Array<SubMenuItemProps>;
    showIf?: any;
}

interface SubMenuItemProps {
    title: string;
    href: string;
    active?: boolean;
}

interface DropDownMenuProps {
    items: Array<SubMenuItemProps>;
}

interface ParentMenuOpenIconProps {
    opened: boolean;
    toggleCollapse: (value: boolean) => void;
}

export default function Sidebar({ logo = 'Buck', items }: SidebarProps) {
    return (
        <aside className={styles.sidebarContainer}>
            <Icon icon={logo} width="120" height="32" />

            <nav>
                {items.map((item) => (
                    <ParentMenuItem key={item.href} {...item} />
                ))}
            </nav>
        </aside>
    );
}

function ParentMenuItem({ title, href, icon, subitems, active = false }: ParentMenuItemProps) {
    const [opened, setOpened] = useState(Array.isArray(subitems) && subitems.length > 0 && active);
    const pathname = window.location.pathname;
    const navigate = useNavigate();

    return (
        <>
            <div
                onClick={() => {
                    navigate(href);
                }}
                className={`${styles.link} ${
                    active || href === pathname || (subitems && subitems.find((element) => element.href === pathname))
                        ? styles.active
                        : styles.inactive
                }`}
            >
                <Icon icon={icon} height="20" width="20" />
                <Text tagName="span" className={[styles.text]}>
                    {title}
                </Text>
                {subitems ? <ParentMenuOpenIcon opened={opened} toggleCollapse={setOpened} /> : ''}
            </div>
            {opened && subitems ? <DropDownMenu items={subitems} /> : ''}
        </>
    );
}

function ParentMenuOpenIcon({ opened, toggleCollapse }: ParentMenuOpenIconProps) {
    const clickHandler = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();

        toggleCollapse(!opened);
    };

    return (
        <div className={styles.parentMenuOpenIcon} onClick={clickHandler}>
            <Icon icon={opened ? 'MinusSign' : 'PlusSign'} height="8" width="8" className={[styles.icon]} />
        </div>
    );
}

function DropDownMenu({ items }: DropDownMenuProps) {
    return (
        <section className={styles.dropdownMenu}>
            {items.map((item) => (
                <SubMenuItem key={item.href} {...item} />
            ))}
        </section>
    );
}

function SubMenuItem({ title, href, active = false }: SubMenuItemProps) {
    const navigate = useNavigate();
    const pathname = window.location.pathname;

    const linkClasses = new Set(['flex', FontSizes.Small, 'relative', 'py-4', 'items-center']);

    if (active) linkClasses.add(TextColors.Greyscale900);
    else linkClasses.add(TextColors.Greyscale500).add('px-6');

    return (
        <div
            onClick={() => navigate(href)}
            className={`${styles.subMenuItem} ${
                active || href === pathname ? styles.activeSubMenuItem : styles.inActiveSubMenuItem
            }`}
        >
            {active || href === pathname ? (
                <Icon icon="CircleWithDot" height="10" width="10" className={[styles.icon]} />
            ) : (
                ''
            )}
            {title}
        </div>
    );
}
