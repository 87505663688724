import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

const convertTimeToAccountTimezone = (time, accountTimeZone) => {
    return dayjs(time).tz(accountTimeZone);
};

export { convertTimeToAccountTimezone };
