import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';

// components
import { Button, Card, Table, Input } from 'antd';
import { SearchLogo } from 'assets/svg';

// helpers
import { axiosClient, apiURL } from 'service';
import notify from 'utils/notification';
import { convertTimeToAccountTimezone } from 'utils/formatter';

// context
import { AuthContext } from 'context';
interface IAppointment {
    id?: string;
    reason: string;
    job_types?: string[];
    job_category: string;
    preferred_time?: string;
    locationUnit?: string;
    locationFullAddress?: string;
}

interface IAppointmentResponse {
    id: string;
    status: string;
    job_type: string;
    confirmed_time: any;
    last_action: string;
    reason: any;
    conversation_id: string;
    customer_name: string;
    location_full_address: string;
    created_at: string;
    updated_at: string;
    customer: {
        id: string;
        name: string;
        conversations_count: number | null;
        bookings_count: number | null;
    };
    location: {
        id: string;
        unit: string;
        street: string;
        city: string;
        state: string;
        zip_code: string;
        country: string;
        longitude: number | null;
        latitude: number | null;
        full_address: string;
    };
}

export default function Appointments() {
    const {
        state: { user },
    } = useContext(AuthContext);

    const account_timezone = user?.memberships?.find((item: any) => item.account.id === user.active_account_id)?.account
        ?.timezone;

    const [appointments, setAppointments] = useState<IAppointment[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);

    const getAppointments = async () => {
        setIsLoading(true);
        try {
            const res = await axiosClient.get(`${apiURL.bookings}?page=${currentPage}`);
            setAppointments(
                res?.data?.data?.map((appointment: IAppointmentResponse) => ({
                    key: appointment.id,
                    id: appointment.id,
                    last_action: appointment.last_action,
                    reason: appointment.reason,
                    customer: appointment?.customer,
                    job_type: appointment.job_type,
                    confirmed_time: appointment.confirmed_time,
                    locationUnit: appointment?.location?.unit,
                    locationFullAddress: appointment?.location?.full_address,
                })) || [],
            );

            setTotalCount(res?.data?.meta.total);
        } catch (error) {
            notify({ type: 'error', message: error?.toString() || 'Error fetching appointments' });
            console.error('error fetching appointments', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDownloadAppointments = async () => {
        setIsLoading(true);
        try {
            // download csv
            const res = await axiosClient.get('/bookings/download');
            const csvData = res.data;
            const csvBlob = new Blob([csvData], { type: 'text/csv' });
            const csvUrl = URL.createObjectURL(csvBlob);
            const link = document.createElement('a');
            link.href = csvUrl;
            link.download = 'bookings.csv';
            link.click();
        } catch (error) {
            notify({ type: 'error', message: error?.toString() || 'Error downloading appointments' });
            console.error('error downloading appointments', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getAppointments();
    }, [currentPage]);

    const columns = [
        {
            title: 'CUSTOMER',
            dataIndex: 'customer',
            key: 'customer',
            render: (customer: any) => {
                return <Link to={`/customer/${customer.id}`}>{customer?.name || 'N/A'}</Link>;
            },
        },
        {
            title: 'LAST ACTION',
            dataIndex: 'last_action',
            key: 'last_action',
            render: (item: string) => <div className="capitalize">{item ? item : '-'}</div>,
        },
        {
            title: 'JOB TYPE',
            dataIndex: 'job_type',
            key: 'job_type',
            render: (item: string) => <div>{item ? item : '-'}</div>,
        },
        {
            title: 'LOCATION',
            dataIndex: 'locationFullAddress',
            key: 'locationFullAddress',
            render: (item: string) => <div>{item ? item : '-'}</div>,
        },
        {
            title: 'CONFIRMED TIME',
            dataIndex: 'confirmed_time',
            key: 'confirmed_time',
            render: (item: string) => {
                return (
                    <div>
                        {item ? convertTimeToAccountTimezone(item, account_timezone).format('MMM D h:mm A') : '-'}
                    </div>
                );
            },
        },
    ];

    return (
        <Card className="flex-1 h-min" bordered={false}>
            <div>
                <div className="flex justify-between mb-5">
                    <Input className="max-w-lg" placeholder="Search Bookings" prefix={<SearchLogo className="w-4" />} />
                    <Button onClick={() => handleDownloadAppointments()} type="primary">
                        Download Bookings
                    </Button>
                </div>
                <Table
                    className="flex flex-col flex-1 h-full"
                    loading={isLoading}
                    dataSource={appointments}
                    columns={columns}
                    onChange={(pagination) => {
                        setCurrentPage(pagination.current || 1);
                    }}
                    pagination={{ total: totalCount, showSizeChanger: false }}
                />
            </div>
        </Card>
    );
}
