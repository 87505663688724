import React from 'react';
import { Button } from 'antd';
import { MinusLogo, PlusLogo, AddCircleGreyLogo, CancelLogo } from 'assets/svg';

interface ServiceableTypesProps {
    visibleMenus: string[];
    playbook: any;
    setPlaybook: (prevState: any) => void;
    setVisibleMenus: (prevState: any) => void;
}

const pageKey = 'serviceable-types';

const ServiceableTypes: React.FC<ServiceableTypesProps> = ({
    playbook = {},
    setPlaybook,
    visibleMenus,
    setVisibleMenus,
}) => {
    const serviceableTypes = playbook.serviceable_types || [];

    const handleServiceableTypeClick = (category: string, type: string) => {
        setPlaybook((prevState: any) => {
            const updatedData = prevState?.serviceable_types?.map((item: any) => {
                if (item?.type === type && item?.category === category) {
                    return {
                        ...item,
                        active: !item?.active,
                    };
                }
                return item;
            });

            return {
                ...prevState,
                serviceable_types: [...updatedData],
            };
        });
    };

    // Categorize serviceable types by category
    const categorizedServiceableTypes = serviceableTypes.reduce((acc: any, item: any) => {
        const { category } = item;
        if (!acc[category]) {
            acc[category] = [];
        }
        acc[category].push(item);
        return acc;
    }, {});

    return (
        <div>
            <div className={'border rounded-10 border-solid border-greyscale-200 p-6 bg-white mt-2 mb-6'}>
                <div className="flex justify-between items-center mb-6">
                    <div className="flex">
                        <div className="font-bold text-lg text-greyscale-900 mr-3">Serviceable Types</div>
                        <div className="flex items-center justify-center rounded-20 bg-green-600 text-white text-xs px-3 py-1">
                            {serviceableTypes?.filter((item: any) => item?.active)?.length} Selected Types
                        </div>
                    </div>
                    <Button
                        onClick={() =>
                            setVisibleMenus((prevState: any) =>
                                prevState.includes(pageKey)
                                    ? prevState.filter((item: string) => item !== pageKey)
                                    : [...prevState, pageKey],
                            )
                        }
                        className={`w-6 h-6 p-0 ${visibleMenus.includes(pageKey) && 'bg-greyscale-100'}`}
                    >
                        {!visibleMenus.includes(pageKey) ? <MinusLogo /> : <PlusLogo />}
                    </Button>
                </div>
                {!visibleMenus.includes(pageKey) && (
                    <div>
                        {Object.keys(categorizedServiceableTypes)?.map((category: string, index: number) => (
                            <div key={index} className="mb-3">
                                <div className="font-bold text-xs capitalize text-greyscale-900 mb-3">
                                    {category} Types
                                </div>
                                <div className="flex flex-wrap">
                                    {categorizedServiceableTypes[category]?.map((type: any, index: number) => (
                                        <div key={index} className="flex items-center mr-4 mb-3">
                                            <Button
                                                onClick={() => handleServiceableTypeClick(category, type.type)}
                                                className={`${type.active ? 'bg-green-600 text-white' : ''}`}
                                            >
                                                {type?.type}
                                                {type.active ? (
                                                    <CancelLogo className="ml-1.5" />
                                                ) : (
                                                    <AddCircleGreyLogo className="ml-1.5" />
                                                )}
                                            </Button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ServiceableTypes;
