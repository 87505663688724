import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// components
import { Button, Row, Col, Checkbox, Tree, notification } from 'antd';
import Breadcrumb from 'components/breadcrumb';
import Spinner from 'components/spinner';
import { SaveLogo, EqualLogo } from 'assets/svg';

// assets
import { CircleDownLogo, CircleUpLogo } from 'assets/svg';

// helpers
import { axiosClient, apiURL } from 'service';
import { v4 as uuidv4 } from 'uuid';

// context
import { AuthContext } from 'context';

// types
interface ITrades {
    id: string;
    name: string;
    serviceId?: number;
}

interface IServices {
    [key: string]: {
        active: boolean;
        fsm_options: any;
    };
}

const Services = () => {
    const navigate = useNavigate();
    const {
        state: { user },
    } = useContext(AuthContext);

    const [data, setData] = useState<any[]>([]);
    const [trades, setTrades] = useState<ITrades[]>([]);
    const [selectedEquipmentTypes, setSelectedEquipmentTypes] = useState<any[]>([]);
    const [serviceTypes, setServiceTypes] = useState<any[]>([]);

    const [checkedTrades, setCheckedTrades] = useState<ITrades[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const getServices = async () => {
        try {
            setIsLoading(true);
            const response = await axiosClient.get(apiURL.services);
            const data = response?.data;
            setData(data);

            const trades = new Map();
            data?.forEach(({ trade }: { trade: any }) => trades.set(trade?.id, trade?.name));

            const tradesArray = Array.from(trades).map(([id, name]) => {
                const serviceId = data.find((service: any) => service.trade.id === id)?.id;
                const totalCount = data.filter((service: any) => service.trade.id === id).length;
                const activeCount = data.filter((service: any) => service.trade.id === id && service.active).length;

                return { id, name, serviceId, totalCount, activeCount };
            });
            const selectedTrades = tradesArray.filter((trade) => trade?.activeCount > 0);
            setTrades(tradesArray);
            setCheckedTrades(selectedTrades);
            getEquipmentTypesByTrades(
                selectedTrades.map((trade) => trade.id),
                data,
            );
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const getEquipmentTypesByTrades = async (tradesData: string[], data: any) => {
        const equipments = tradesData.map((tradeId: any) => {
            const service = data.find((service: any) => service.trade.id === tradeId);

            const equipmentsByTradeId = data.filter((service: any) => service?.trade?.id === tradeId);
            const uniqueEquipments = new Map();
            equipmentsByTradeId.forEach(({ id, equipment_type }: { id: string; equipment_type: any }) => {
                const totalCount = equipmentsByTradeId.filter(
                    (service: any) => service.equipment_type.id === equipment_type.id,
                ).length;

                const activeCount = equipmentsByTradeId.filter(
                    (service: any) => service.equipment_type.id === equipment_type.id && service.active,
                ).length;

                uniqueEquipments.set(equipment_type?.id, {
                    name: equipment_type?.name,
                    serviceId: id,
                    totalCount,
                    activeCount,
                });
            });

            const equipmentsArray = Array.from(uniqueEquipments).map(
                ([id, { name, serviceId, activeCount, totalCount }]) => ({
                    id,
                    name,
                    serviceId,
                    activeCount,
                    totalCount,
                    checked: activeCount > 0 ? true : false,
                }),
            );

            return {
                name: service.trade?.name,
                id: tradeId,
                serviceId: service?.id,
                items: equipmentsArray,
            };
        });

        const treeData = equipments.map((trade: any) => {
            return {
                // trade
                id: trade.id,
                key: uuidv4(),
                title: trade.name,
                checkable: false,
                // equipment
                children: trade.items.map((equipment: any) => {
                    const servicetypes = getServiceTypesByEquipmentId(trade, equipment, data);
                    return {
                        title: equipment.name,
                        key: uuidv4(),
                        id: equipment.id,
                        checkable: false,
                        // serviceTypes
                        children: servicetypes,
                    };
                }),
            };
        });

        setSelectedEquipmentTypes(equipments);

        setServiceTypes(treeData);
    };

    const getServiceTypesByEquipmentId = (trade: any, equipment: any, data: any) => {
        const serviceTypes = data.filter(
            (item: any) => item.equipment_type.id === equipment.id && item.trade.id === trade.id,
        );

        const uniqueServiceTypes = new Map();
        serviceTypes.forEach(({ id, service_type, active, fsm_options }: any) => {
            uniqueServiceTypes.set(service_type.id, { name: service_type.name, active, fsm_options, serviceId: id });
        });

        return Array.from(uniqueServiceTypes).map(([id, { name, serviceId, active, fsm_options }]) => ({
            title: name,
            key: uuidv4(),
            tradeId: trade.id,
            serviceId,
            active,
            fsm_options,
            equipmentId: equipment.id,
            serviceTypeId: id,
            checkable: true,
        }));
    };

    const handleTradeChange = (trade: any) => {
        const index = checkedTrades.findIndex((el) => el.id === trade.id);
        if (index === -1) {
            setCheckedTrades([...checkedTrades, trade]);
            getEquipmentTypesByTrades([...checkedTrades.map((el) => el.id), trade.id], data);
        } else {
            const filteredTrades = checkedTrades.filter((el) => el.id !== trade.id);
            setCheckedTrades(filteredTrades);
            getEquipmentTypesByTrades([...filteredTrades.map((el: any) => el.id)], data);
        }
    };

    const handleSelectEquipmentType = ({ trade, equipment, checked }: any) => {
        setSelectedEquipmentTypes((prevState) => {
            return prevState.map((el) => {
                if (el.id === trade.id) {
                    return {
                        ...el,
                        items: el.items.map((item: any) => {
                            if (item.id === equipment.id) {
                                return { ...item, checked };
                            }
                            return item;
                        }),
                    };
                }
                return el;
            });
        });

        const tradeIndex = serviceTypes.findIndex((el) => el.id === trade.id);

        const seriveTypes = new Map();
        const equipmentTypes = data.filter((item) => item.equipment_type.id === equipment.id);

        equipmentTypes.forEach(({ id, service_type, active, fsm_options }: any) => {
            seriveTypes.set(service_type.id, { name: service_type.name, active, fsm_options, serviceId: id });
        });

        if (tradeIndex === -1) {
            setServiceTypes([
                ...serviceTypes,
                {
                    // trade
                    id: trade.id,
                    key: uuidv4(),
                    title: trade.name,
                    checkable: false,
                    // equipment
                    children: [
                        {
                            title: equipment.name,
                            key: uuidv4(),
                            id: equipment.id,
                            checkable: false,
                            // service
                            children: Array.from(seriveTypes).map(([id, { name, serviceId, fsm_options, active }]) => ({
                                title: name,
                                key: uuidv4(),
                                tradeId: trade.id,
                                serviceId,
                                active,
                                fsm_options,
                                equipmentId: equipment.id,
                                serviceTypeId: id,
                                checkable: true,
                            })),
                        },
                    ],
                },
            ]);
        } else {
            setServiceTypes((prevState) => {
                return prevState.map((el: any) => {
                    if (el.id === trade.id) {
                        const equipmentIndex = el.children.findIndex((el: any) => el.id === equipment.id);
                        if (equipmentIndex === -1) {
                            return {
                                ...el,
                                children: [
                                    ...el.children,
                                    {
                                        title: equipment.name,
                                        key: uuidv4(),
                                        id: equipment.id,
                                        checkable: false,
                                        // service
                                        children: Array.from(seriveTypes).map(
                                            ([id, { name, serviceId, active, fsm_options }]) => ({
                                                title: name,
                                                key: uuidv4(),
                                                serviceTypeId: id,
                                                tradeId: trade.id,
                                                fsm_options,
                                                active,
                                                serviceId,
                                                equipmentId: equipment.id,
                                                checkable: true,
                                            }),
                                        ),
                                    },
                                ],
                            };
                        } else {
                            return {
                                ...el,
                                children: el.children.filter((el: any) => el.id !== equipment.id),
                            };
                        }
                    }
                    return el;
                });
            });
        }
    };

    const handleUnselectTradeClick = () => {
        if (checkedTrades.length > 0) {
            setCheckedTrades([]);
            setSelectedEquipmentTypes([]);
            setServiceTypes([]);
        } else {
            setCheckedTrades(trades);
            getEquipmentTypesByTrades(
                trades.map((trade) => trade.id),
                data,
            );
        }
    };

    const handleUnselectEquipmentTypeClick = (isSelect: boolean) => {
        if (isSelect) {
            setServiceTypes([]);
            setSelectedEquipmentTypes((prevState) => {
                return prevState.map((el) => {
                    return {
                        ...el,
                        items: el.items.map((item: any) => {
                            return { ...item, checked: false };
                        }),
                    };
                });
            });
        } else {
            getEquipmentTypesByTrades(
                trades.map((trade) => trade.id),
                data,
            );
        }
    };

    const handleServiceTypeCheck = (checkedKeys: any, e: any) => {
        setServiceTypes((prevState) => {
            return prevState.map((trade: any) => {
                return {
                    ...trade,
                    children: trade.children.map((equipment: any) => {
                        return {
                            ...equipment,
                            children: equipment.children.map((serviceType: any) => {
                                if (serviceType.key === e.node.key) {
                                    return { ...serviceType, active: e.checked };
                                }
                                return serviceType;
                            }),
                        };
                    }),
                };
            });
        });
    };

    const handleUnselectServiceType = (isSelect: boolean) => {
        setServiceTypes((prevState) => {
            return prevState.map((trade: any) => {
                return {
                    ...trade,
                    children: trade.children.map((equipment: any) => {
                        return {
                            ...equipment,
                            children: equipment.children.map((serviceType: any) => {
                                return { ...serviceType, active: !isSelect };
                            }),
                        };
                    }),
                };
            });
        });
    };

    const handleSaveClick = async () => {
        const services: IServices = {};

        serviceTypes.forEach((trade: any) => {
            trade.children.forEach((equipment: any) => {
                equipment.children.forEach((serviceType: any) => {
                    services[serviceType.serviceId] = {
                        active: serviceType.active,
                        fsm_options: serviceType.fsm_options,
                    };
                });
            });
        });

        const payload = {
            services: Object.keys(services).map((key) => {
                return {
                    id: key,
                    attributes: { active: services[key].active, fsm_options: services[key].fsm_options },
                };
            }),
        };

        try {
            await axiosClient.put(apiURL.services + '/batch', payload);
            getServices();
            notification.success({
                message: 'Success',
                description: 'Services updated successfully',
            });
        } catch (error) {
            console.error('error fetching arrival window', error);
            notification.error({
                message: 'Error',
                description: 'Error fetching arrival window',
            });
        }
    };

    useEffect(() => {
        getServices();
    }, []);

    const serviceTypesKeys = serviceTypes
        ?.map((trade) => {
            return trade?.children?.map((equipment: any) => {
                return equipment?.children?.map((serviceType: any) => {
                    return { key: serviceType.key, active: serviceType.active };
                });
            });
        })
        ?.flat(Infinity);

    const activeServiceTypes = serviceTypesKeys?.filter((el: any) => el.active).map((el: any) => el.key);

    const isServiceTypeSelect = serviceTypes.some((trade: any) =>
        trade.children.some((equipment: any) => equipment.children.some((serviceType: any) => serviceType.active)),
    );

    const isEquipmentTypeSelect = selectedEquipmentTypes.some((el) => el.items.some((item: any) => item.checked));

    const fsmStatus = user?.memberships?.find((el: any) => el.account_id === user.active_account_id)?.account?.features
        ?.fsm;
    return (
        <div className="flex flex-col flex-1 overflow-hidden">
            <div className="fixed top-10">
                <Breadcrumb items={breadcrumbItems} />
            </div>

            <div
                className={
                    'flex flex-col flex-1 border rounded-10 border-solid border-greyscale-200 p-6 bg-white mt-2 overflow-hidden'
                }
            >
                <div className="flex justify-between items-center mb-6">
                    <div>
                        <div className="font-bold text-lg text-greyscale-900">Services</div>
                        <div className="text-sx text-greyscale-900 font-normal">
                            Some Desc. comes here perfectly. It has must to be here.
                        </div>
                    </div>
                    <div className="flex">
                        {fsmStatus && (
                            <Button
                                onClick={() => navigate('/settings/match-with-fsm')}
                                icon={<EqualLogo />}
                                className="mr-4"
                            >
                                Match with FSM
                            </Button>
                        )}
                        <Button onClick={handleSaveClick} type="primary" icon={<SaveLogo />}></Button>
                    </div>
                </div>
                <div className="border border-solid border-t-0 border-greyscale-250 my-6" />
                <Row className="flex flex-1" gutter={[24, 24]}>
                    {/* trades */}
                    <Col sm={24} lg={8}>
                        <div className="flex flex-col h-full border rounded-10 border-solid border-greyscale-250">
                            <div className="flex justify-between items-center p-6">
                                <div className="font-bold text-xs text-greyscale-900 uppercase">Trades</div>
                                <div
                                    onClick={handleUnselectTradeClick}
                                    className="font-normal text-xs text-primary underline cursor-pointer"
                                >
                                    {checkedTrades.length > 0 ? 'Unselect All' : 'Select All'}
                                </div>
                            </div>
                            <div className="border border-solid border-t-0 border-greyscale-250" />
                            <div className="flex flex-col flex-1 shrink-1 basis-0 p-6 overflow-scroll">
                                {trades.map((trade: any) => (
                                    <div
                                        key={trade.id}
                                        className="flex items-center justify-between border rounded-10 border-solid border-greyscale-250 mb-6 px-6 py-5 "
                                    >
                                        <div className="flex items-center">
                                            <Checkbox
                                                onChange={() => handleTradeChange(trade)}
                                                checked={checkedTrades.some((el) => el.id === trade.id)}
                                                className="mr-3"
                                            />
                                            <div className="text-greyscale-900 text-sm font-normal">{trade.name}</div>
                                        </div>
                                        <div className="text-greyscale-400 font-normal text-2xs">
                                            {trade.activeCount} / {trade.totalCount}{' '}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Col>
                    {/* Equipment Types */}
                    <Col sm={24} lg={8}>
                        <div className="flex flex-col border h-full rounded-10 border-solid border-greyscale-250">
                            <div className="flex justify-between items-center p-6">
                                <div className="font-bold text-xs text-greyscale-900 uppercase">Equipment Type</div>
                                <div
                                    onClick={() => handleUnselectEquipmentTypeClick(isEquipmentTypeSelect)}
                                    className="font-normal text-xs text-primary underline cursor-pointer"
                                >
                                    {isEquipmentTypeSelect ? 'Unselect All' : 'Select All'}
                                </div>
                            </div>
                            <div className="border border-solid border-t-0 border-greyscale-250" />
                            <div className="p-6 flex flex-wrap">
                                {[{ name: 'All', id: '1' }, ...trades].map((trade: any) => (
                                    <div
                                        onClick={() => handleTradeChange(trade)}
                                        className={`flex items-center justify-center mr-3 mb-3 border rounded-20 border-solid max-w-fit border-greyscale-250 px-3 py-1 text-xs font-normal text-greyscale-900 cursor-pointer ${
                                            checkedTrades.some((el) => el.id === trade.id)
                                                ? 'bg-primary text-white border-none'
                                                : ''
                                        }`}
                                        key={trade.id}
                                    >
                                        {trade.name}
                                    </div>
                                ))}
                            </div>
                            <div className="border border-solid border-t-0 border-greyscale-250" />
                            <div className="flex flex-col flex-1 shrink-1 basis-0 overflow-scroll p-6">
                                {selectedEquipmentTypes?.map((trade: any) => (
                                    <div key={trade.id}>
                                        <div className="mb-3 text-greyscale-900 font-bold text-xs">{trade.name}</div>
                                        {trade.items.map((item: any) => (
                                            <div
                                                key={item.id}
                                                className="flex items-center justify-between border rounded-10 border-solid border-greyscale-250 mb-6 px-6 py-5 "
                                            >
                                                <div className="flex items-center">
                                                    <Checkbox
                                                        onChange={(e) =>
                                                            handleSelectEquipmentType({
                                                                trade,
                                                                equipment: item,
                                                                checked: e.target.checked,
                                                            })
                                                        }
                                                        checked={item.checked}
                                                        className="mr-3"
                                                    />
                                                    <div className="text-greyscale-900 text-sm font-normal">
                                                        {item.name}
                                                    </div>
                                                </div>
                                                <div className="text-greyscale-400 font-normal text-2xs">
                                                    {item?.activeCount} / {item?.totalCount}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Col>
                    {/* service types */}
                    <Col sm={24} lg={8}>
                        <div className="flex flex-col h-full border rounded-10 border-solid border-greyscale-250">
                            <div className="flex justify-between items-center p-6">
                                <div className="font-bold text-xs text-greyscale-900 uppercase">SERVICE TYPE</div>
                                <div
                                    onClick={() => {
                                        handleUnselectServiceType(isServiceTypeSelect);
                                    }}
                                    className="font-normal text-xs text-primary underline cursor-pointer"
                                >
                                    {isServiceTypeSelect ? 'Unselect All' : 'Select All'}
                                </div>
                            </div>
                            <div className="border border-solid border-t-0 border-greyscale-250" />
                            <div className="flex flex-col flex-1 shrink-1 basis-0 overflow-scroll text-sx text-greyscale-900 font-normal p-6">
                                <Tree
                                    switcherIcon={(val: any) => {
                                        if (val.expanded) {
                                            return <CircleDownLogo />;
                                        }
                                        return <CircleUpLogo />;
                                    }}
                                    onCheck={(checkedKeys: any, e: any) => {
                                        handleServiceTypeCheck(checkedKeys, e);
                                    }}
                                    checkedKeys={activeServiceTypes}
                                    checkable
                                    selectable={false}
                                    treeData={serviceTypes}
                                    defaultExpandAll
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <Spinner visible={isLoading} />
        </div>
    );
};

const breadcrumbItems = [
    {
        label: 'General Settings',
        href: '#',
        disabled: true,
    },
    {
        label: 'Services',
        href: '/settings/services',
    },
];

export default Services;
