import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

// components
import { Button, Card, Table, Input, Modal, Typography, Dropdown, Select } from 'antd';
import InviteUserDialog from 'dialogs/invite-user';
import { SearchLogo, DotHorizontal } from 'assets/svg';

// helpers
import { axiosClient, apiURL } from 'service';
import notify from 'utils/notification';

// assets
import { SaveLogo } from 'assets/svg';

const { Text } = Typography;

interface IUserOfMemberShip {
    id: string;
    email: string;
    name: string;
    role: string;
    photo_url: string | null;
    created_at: string;
    updated_at: string;
    active_account_id: string;
}

interface IMembership {
    id: string;
    role: string;
    account_id: string;
    created_at: string;
    updated_at: string;
    user: IUserOfMemberShip;
}

export default function Users() {
    const [memberships, setMemberships] = useState<IMembership[]>([]);
    const [inviteDialogVisible, setInviteDialogVisible] = useState(false);
    const [openRoleChangeModal, setOpenRoleChangeModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [userRole, setUserRole] = useState('');
    const [openedRecord, setOpenedRecord] = useState<any>();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);

    const getMemberships = async () => {
        setIsLoading(true);
        try {
            const res = await axiosClient.get(`/memberships?page=${currentPage}`);
            setMemberships(res?.data?.data?.map((membership: IMembership) => ({ ...membership, key: membership.id })));

            setTotalCount(res?.data?.meta.total);
        } catch (error) {
            notify({ type: 'error', message: error?.toString() || 'Error fetching memberships' });
            console.error('error fetching users', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleInviteUser = async (data: any) => {
        try {
            await axiosClient.post('/users/invite', {
                user: {
                    ...data,
                },
            });
            notify({ type: 'success', message: 'User invited successfully' });
            getMemberships();
            setInviteDialogVisible(false);
        } catch (error) {
            notify({ type: 'error', message: error?.toString() || 'Error inviting user' });
            console.error('error inviting user', error);
        }
    };

    const handleUserRoleChange = async () => {
        setIsLoading(true);
        try {
            await axiosClient.put(`${apiURL.memberships}/${openedRecord.id}`, {
                role: userRole,
            });

            notify({ type: 'success', message: 'User role changed successfully' });
            window.location.reload();
            getMemberships();
            setIsLoading(false);
            setOpenRoleChangeModal(false);
        } catch (error) {
            setIsLoading(false);
            notify({ type: 'error', message: error?.toString() });
        }
    };

    useEffect(() => {
        getMemberships();
    }, [currentPage]);

    const columns = [
        {
            title: 'USER NAME',
            dataIndex: 'user',
            key: 'name',
            render: (user: IUserOfMemberShip) => <div>{user.name ? user.name : '-'}</div>,
        },
        {
            title: 'EMAIL',
            dataIndex: 'user',
            key: 'email',
            render: (user: IUserOfMemberShip) => <div>{user.email ? user.email : '-'}</div>,
        },
        {
            title: 'ROLE',
            dataIndex: 'role',
            key: 'role',
            render: (item: string) => <div>{item ? item : '-'}</div>,
        },
        {
            title: 'CREATED DATE',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (item: string) => <div>{item ? dayjs(item).format('D MMM h:mm A') : '-'}</div>,
        },
        {
            title: 'ACTIONS',
            dataIndex: 'actions',
            key: 'actions',
            render: (_: any, record: any) => (
                <Dropdown
                    menu={{
                        items: [
                            {
                                key: '1',
                                label: (
                                    <div
                                        onClick={() => {
                                            setOpenRoleChangeModal(true);
                                            setOpenedRecord(record);
                                            setUserRole(record.role);
                                        }}
                                    >
                                        Change User Role
                                    </div>
                                ),
                            },
                        ],
                    }}
                    placement="bottom"
                >
                    <DotHorizontal />
                </Dropdown>
            ),
        },
    ];

    return (
        <Card className="flex-1 h-min" bordered={false}>
            <div>
                <div className="flex justify-between mb-5">
                    <Input className="max-w-lg" placeholder="Search Users" prefix={<SearchLogo className="w-4" />} />
                    <Button onClick={() => setInviteDialogVisible(true)} type="primary">
                        Invite User
                    </Button>
                </div>
                <Table
                    className="flex flex-col flex-1 h-full"
                    loading={isLoading}
                    dataSource={memberships}
                    columns={columns}
                    onChange={(pagination) => {
                        setCurrentPage(pagination.current || 1);
                    }}
                    pagination={{ total: totalCount, showSizeChanger: false }}
                />
            </div>
            <InviteUserDialog
                visible={inviteDialogVisible}
                onClose={() => setInviteDialogVisible(false)}
                onSave={handleInviteUser}
            />
            <Modal
                title="Delete"
                open={openDeleteModal}
                onOk={() => setOpenDeleteModal(false)}
                onCancel={() => setOpenDeleteModal(false)}
                okText="Yes"
                cancelText="No"
            >
                <Text>Are you sure you want to delete this user?</Text>
            </Modal>
            <Modal
                title="Change Role"
                open={openRoleChangeModal}
                onCancel={() => setOpenRoleChangeModal(false)}
                footer={[
                    <Button
                        className="flex-1"
                        key="submit"
                        type="primary"
                        onClick={handleUserRoleChange}
                        icon={<SaveLogo />}
                    >
                        Save
                    </Button>,
                ]}
            >
                <Select
                    value={userRole}
                    style={{ width: '100%' }}
                    onChange={(value) => setUserRole(value)}
                    options={[
                        { value: 'user', label: 'User' },
                        { value: 'owner', label: 'Owner' },
                    ]}
                />
            </Modal>
        </Card>
    );
}
