import React, { useState, useEffect } from 'react';

// components
import { Button, Dropdown, Input, Table, Popconfirm } from 'antd';
import Breadcrumb from 'components/breadcrumb';
import EditArrivalWindow from 'dialogs/edit-arrival-window';
import type { MenuProps } from 'antd';

// helpers
import notify from 'utils/notification';

// assets
import { AddCircleLogo, DotVertical, EditIcon, SearchLogo, SortByDownSvg, SortByUpSvg } from 'assets/svg';

// helpers
import dayjs from 'dayjs';
import { axiosClient, apiURL } from 'service';

interface DataType {
    key: React.Key;
    title: string;
    from_hour: string;
    to_hour: string;
}

type SortOrder = 'ascend' | 'descend' | null;

const ArrivalWindow = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [arrivalWindows, setArrivalWindows] = useState<any[]>([]);
    const [selectedArrivalWindow, setSelectedArrivalWindow] = useState<any | null>(null);
    const [editArrivalWindow, setEditArrivalWindow] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);

    const getArrivalWindow = async () => {
        setIsLoading(true);
        axiosClient
            .get(`${apiURL.arrivalWindows}?page=${currentPage}`)
            .then((response) => {
                const data = response?.data.map((el: any, i: any) => ({ key: i, ...el })) || [];
                setArrivalWindows(data);

                setTotalCount(response?.data?.meta?.total);
            })
            .catch((error) => {
                notify({ type: 'error', message: error?.toString() || 'error fetching arrival window' });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleSave = () => {
        getArrivalWindow();
        setSelectedArrivalWindow(null);
        setEditArrivalWindow(false);
    };

    const handleDelete = (id: string) => {
        setIsLoading(true);
        axiosClient
            .delete(`${apiURL.arrivalWindows}/${id}`)
            .then(() => {
                notify({ type: 'success', message: 'Arrival window deleted successfully' });
                getArrivalWindow();
            })
            .catch((error) => {
                notify({ type: 'error', message: `Error deleting arrival window: ${error?.toString()}` });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getArrivalWindow();
    }, [currentPage]);

    const sortColums = {
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'] as SortOrder[],
        sortIcon: ({ sortOrder }: any) => {
            if (!sortOrder || sortOrder === 'ascend') {
                return <SortByDownSvg />;
            }
            return <SortByUpSvg className="text-primary" />;
        },
    };

    const columns = [
        {
            title: 'TITLE',
            dataIndex: 'title',
            key: 'title',
            ...sortColums,
        },
        {
            title: 'STARTING TIME',
            dataIndex: 'from_hour',
            key: 'from_hour',
            render: (item: string) => <div>{item ? dayjs(item, 'HH:mm').format('h:mm A') : '-'}</div>,
            ...sortColums,
        },
        {
            title: 'ENDING TIME',
            dataIndex: 'to_hour',
            key: 'to_hour',
            render: (item: string) => <div>{item ? dayjs(item, 'HH:mm').format('h:mm A') : '-'}</div>,
            ...sortColums,
        },
        {
            title: 'ACTIONS',
            dataIndex: 'actions',
            key: 'actions',
            render: (_: any, record: any) => {
                const items: MenuProps['items'] = [
                    {
                        key: '1',
                        label: (
                            <div className="flex items-center justify-center">
                                <Popconfirm
                                    title="Are you sure you want to delete this record?"
                                    okText="Yes"
                                    cancelText="No"
                                    onConfirm={() => {
                                        handleDelete(record.id);
                                    }}
                                >
                                    <div>Delete</div>
                                </Popconfirm>
                            </div>
                        ),
                    },
                ];
                return (
                    <div className="flex items-center">
                        <EditIcon
                            className="mr-2 cursor-pointer"
                            onClick={() => {
                                setSelectedArrivalWindow(record);
                                setEditArrivalWindow(true);
                            }}
                        />
                        <Dropdown menu={{ items }} placement="bottom" arrow={{ pointAtCenter: true }}>
                            <DotVertical className="mr-2 cursor-pointer" />
                        </Dropdown>
                    </div>
                );
            },
        },
    ];

    const rowSelection = {
        getCheckboxProps: (record: DataType) => ({
            title: record.title,
        }),
    };

    return (
        <div className="flex flex-col flex-1">
            <div className="fixed top-10">
                <Breadcrumb items={breadcrumbItems} />
            </div>
            <div className="mt-2">
                <div className="flex justify-between mb-6">
                    <Input prefix={<SearchLogo />} className="max-w-sm" placeholder="Search on table" />
                    <Button icon={<AddCircleLogo />} onClick={() => setEditArrivalWindow(true)} type="primary">
                        Create New Arrival Window
                    </Button>
                </div>
                <Table
                    className="flex flex-col flex-1 h-full"
                    rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                    }}
                    loading={isLoading}
                    dataSource={arrivalWindows}
                    columns={columns}
                    onChange={(pagination) => {
                        setCurrentPage(pagination.current || 1);
                    }}
                    pagination={{ total: totalCount, showSizeChanger: false }}
                />
            </div>
            <EditArrivalWindow
                arrivalWindow={selectedArrivalWindow}
                visible={editArrivalWindow}
                onClose={() => {
                    setEditArrivalWindow(false);
                    setSelectedArrivalWindow(null);
                }}
                onSave={() => handleSave()}
            />
        </div>
    );
};

const breadcrumbItems = [
    {
        label: 'General Settings',
        href: '#',
        disabled: true,
    },
    {
        label: 'Arrival Window',
        href: '/settings/arrival-window',
    },
];

export default ArrivalWindow;
