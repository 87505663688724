import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// components
import { Button, Card, Table, Input } from 'antd';
import dayjs from 'dayjs';
import { SearchLogo } from 'assets/svg';

// helpers
import { axiosClient, apiURL } from 'service';
import notify from 'utils/notification';

interface IComplaint {
    id: string;
    status: string;
    reason: string;
    details: string;
    created_at: string;
    updated_at: string;
    customer: {
        id: string;
        name: string;
        conversations_count: number;
        bookings_count: number;
        awaiting_agents_count: number;
        in_conversations_count: number;
    };
}

export default function Complaints() {
    const [complaints, setComplaints] = useState<IComplaint[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);

    const getComplaints = async () => {
        setIsLoading(true);

        try {
            const res = await axiosClient.get(`${apiURL.complaints}?page=${currentPage}`);
            setComplaints(res?.data?.data || []);

            setTotalCount(res?.data?.meta.total);
        } catch (error) {
            notify({ type: 'error', message: error?.toString() || 'Error fetching complaints' });
            console.error('error fetching complaints', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDownloadComplaints = async () => {
        setIsLoading(true);
        try {
            // download csv
            const res = await axiosClient.get('/complaints/download');
            const csvData = res.data;
            const csvBlob = new Blob([csvData], { type: 'text/csv' });
            const csvUrl = URL.createObjectURL(csvBlob);
            const link = document.createElement('a');
            link.href = csvUrl;
            link.download = 'complaints.csv';
            link.click();
        } catch (error) {
            notify({ type: 'error', message: error?.toString() || 'Error downloading complaints' });
            console.error('error downloading complaints', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getComplaints();
    }, [currentPage]);

    const columns = [
        {
            title: 'CUSTOMER',
            dataIndex: 'customer',
            key: 'customer',
            render: (customer: any) => {
                return <Link to={`/customer/${customer.id}`}>{customer?.name || 'N/A'}</Link>;
            },
        },
        {
            title: 'REASON',
            dataIndex: 'reason',
            key: 'reason',
            render: (item: string) => <div>{item ? item : '-'}</div>,
        },
        {
            title: 'CREATE TIME',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (item: string) => <div>{item ? dayjs(item).format('MMM D h:mm A') : '-'}</div>,
        },
        {
            title: 'UPDATE TIME',
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (item: string) => <div>{item ? dayjs(item).format('MMM D h:mm A') : '-'}</div>,
        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            key: 'status',
            render: (item: string) => <div>{item ? item : '-'}</div>,
        },
    ];

    return (
        <Card className="flex-1 h-min" bordered={false}>
            <div>
                <div className="flex justify-between mb-5">
                    <Input
                        className="max-w-lg"
                        placeholder="Search Complaints"
                        prefix={<SearchLogo className="w-4" />}
                    />
                    <Button onClick={() => handleDownloadComplaints()} type="primary">
                        Download Complaints
                    </Button>
                </div>
                <Table
                    className="flex flex-col flex-1 h-full"
                    loading={isLoading}
                    dataSource={complaints}
                    columns={columns}
                    onChange={(pagination) => {
                        setCurrentPage(pagination.current || 1);
                    }}
                    pagination={{ total: totalCount, showSizeChanger: false }}
                />
            </div>
        </Card>
    );
}
