import React, { useContext, useEffect, useState } from 'react';

import type { RadioChangeEvent } from 'antd';

// components
import Card from 'components/card';
import Breadcrumb from 'components/breadcrumb';
import { Button, Radio } from 'antd';
import Spinner from 'components/spinner';
import { SaveLogo } from 'assets/svg';
import notify from 'utils/notification';

// helpers
import { axiosClient, apiURL } from 'service';

// context
import { AuthContext } from 'context';

const Preferences = () => {
    const {
        state: { user },
    } = useContext(AuthContext);

    const [availabilityMethod, setAvailabilityMethod] = useState(null);
    const [bookingExportMethod, setBookingExportMethod] = useState(null);
    const [availabilityMethodVisible, setAvailabilityMethodVisible] = useState(true);
    const [bookingExportMethodVisible, setBookingExportMethodVisible] = useState(true);
    const [fsmStatus, setFsmStatus] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleAvailabilityChange = (e: RadioChangeEvent) => {
        setAvailabilityMethod(e.target.value);
        setBookingExportMethod(null);
    };

    const handleBookingExportMethodChange = (e: RadioChangeEvent) => {
        setBookingExportMethod(e.target.value);
    };

    const handleSettingSave = async () => {
        if (!user?.active_account_id) return;
        setIsLoading(true);
        try {
            await axiosClient.patch(`${apiURL.accounts}/${user?.active_account_id}/settings`, {
                account_setting: {
                    availability_search_method: availabilityMethod,
                    booking_export_method: bookingExportMethod,
                },
            });
            notify({ type: 'success', message: 'Settings updated successfully.' });
        } catch (error) {
            console.error(error);
            notify({ type: 'error', message: 'Failed to update settings.' });
        } finally {
            setIsLoading(false);
        }
    };

    const getAccountPreferences = async () => {
        if (!user?.active_account_id) return;
        setIsLoading(true);
        try {
            const response = await axiosClient.get(`${apiURL.accounts}/${user?.active_account_id}/settings`);
            const { booking_export_method, availability_search_method } = response.data;
            setBookingExportMethod(booking_export_method);
            setAvailabilityMethod(availability_search_method);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const getUserAccount = async (accountID: string) => {
        try {
            const response = await axiosClient.get(`${apiURL.accounts}/${accountID}`);
            const fsmStatus = response?.data?.features.fsm;
            setFsmStatus(fsmStatus);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (user?.active_account_id) {
            getUserAccount(user?.active_account_id);
        }
        getAccountPreferences();
    }, [user?.active_account_id]);

    return (
        <div className="flex flex-col flex-1">
            <div className="fixed top-10">
                <Breadcrumb items={breadcrumbItems} />
            </div>
            <div className="mt-2">
                <Card className="mb-6">
                    <div className="flex justify-between items-center">
                        <div className="font-bold text-lg text-greyscale-900">Select Availability Search Method</div>
                        <Button onClick={() => setAvailabilityMethodVisible((prev) => !prev)} className="w-6 h-6 p-0">
                            {availabilityMethodVisible ? '-' : '+'}
                        </Button>
                    </div>
                    {availabilityMethodVisible && (
                        <>
                            <div className="border border-solid border-t-0 border-greyscale-250 my-6" />
                            <Radio.Group onChange={handleAvailabilityChange} value={availabilityMethod}>
                                <div className="flex flex-col">
                                    <Radio
                                        disabled={!fsmStatus}
                                        className="mb-6 text-sm text-greyscale-900 font-normal"
                                        value={'fsm'}
                                    >
                                        Automatic (ACP - FSM Integration)
                                    </Radio>
                                    <Radio className="text-sm text-greyscale-900 font-normal" value={'manual'}>
                                        Manual (Default)
                                    </Radio>
                                </div>
                            </Radio.Group>
                        </>
                    )}
                </Card>

                <Card className="mb-6">
                    <div className="flex justify-between items-center">
                        <div className="font-bold text-lg text-greyscale-900">Select Booking Export Method</div>
                        <Button onClick={() => setBookingExportMethodVisible((prev) => !prev)} className="w-6 h-6 p-0">
                            {bookingExportMethodVisible ? '-' : '+'}
                        </Button>
                    </div>
                    {bookingExportMethodVisible && (
                        <>
                            <div className="border border-solid border-t-0 border-greyscale-250 my-6" />
                            <Radio.Group onChange={handleBookingExportMethodChange} value={bookingExportMethod}>
                                <div className="flex flex-col">
                                    {fsmStatus && (
                                        <>
                                            <Radio
                                                disabled={availabilityMethod !== 'fsm'}
                                                className="mb-6 text-sm text-greyscale-900 font-normal"
                                                value={'create_unassigned_job_on_fsm'}
                                            >
                                                Create Unassigned job on ST (if FSM integration available)
                                            </Radio>
                                            <Radio
                                                disabled={availabilityMethod !== 'fsm'}
                                                className="mb-6 text-sm text-greyscale-900 font-normal"
                                                value={'create_assigned_job_on_fsm'}
                                            >
                                                Create assigned job on ST (if FSM integration available)
                                            </Radio>
                                        </>
                                    )}
                                    <Radio className="text-sm text-greyscale-900 font-normal" value={'inhouse'}>
                                        Store only on TradesGPT (Default)
                                    </Radio>
                                </div>
                            </Radio.Group>
                        </>
                    )}
                </Card>
                <Spinner visible={isLoading} />
            </div>
            <div className="flex justify-end">
                <Button onClick={handleSettingSave} className="h-9" icon={<SaveLogo />} type="primary">
                    Save
                </Button>
            </div>
        </div>
    );
};

const breadcrumbItems = [
    {
        label: 'General Settings',
        href: '#',
        disabled: true,
    },
    {
        label: 'Preferences',
        href: '/settings/preferences',
    },
];

export default Preferences;
